import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/pclclientpresentation.css";

const PclClientPresentation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "PCL CLIENT PRESENTATION");
	const descriptionImage1 = sameProject.descriptionImage5;
	const descriptionImage2 = sameProject.descriptionImage2;
	const descriptionImage3 = sameProject.descriptionImage3;
	const descriptionImage4 = sameProject.descriptionImage4;
    const adobeInDesignLogo = sameProject.button1;
    const microsoftPowerPointLogo = sameProject.button2;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`PLC CLIENT PRESENTATION | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-pclclientpresentation">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="pclclientpresentation-logo-title">
						<div className="pclclientpresentation-title">
							PCL CLIENT PRESENTATION
						</div>
						
				
					</div>
					<div className="pclclientpresentation-columns">
						{/* Left Column (35% Width) */}
						<div className="pclclientpresentation-left-column">
						I got a request from a Business Manager to create a client presentation with a one-day turnaround. I sourced information and
photos, carefully curated the content, and delivered a polished presentation that received excellent feedback from my entire team.
						</div>
					</div>
					<div className="pclclientpresentation-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="pclclientpresentation-software">
                                <a href="https://www.adobe.com/ca/products/indesign.html">
									<img src={adobeInDesignLogo} alt="adobeInDesignLogo" />
								</a>
								<a href="https://www.microsoft.com/en-ca/microsoft-365/powerpoint">
									<img src={microsoftPowerPointLogo} alt="powerpoint" />
								</a>
							</div>
							<div className="pclclientpresentation-images">
								<img src={descriptionImage1} alt="Image 1" className="grid-image" />
								<img src={descriptionImage2} alt="Image 2" className="grid-image" />
								<img src={descriptionImage3} alt="Image 3" className="grid-image" />
								<img src={descriptionImage4} alt="Image 4" className="grid-image" />
							</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PclClientPresentation;

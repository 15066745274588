import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/midtermmixer.css";

const MidtermMixer = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "PCL MIDTERM MIXER");
	const descriptionImage1 = sameProject.descriptionImage;
	const descriptionImage2 = sameProject.descriptionImage2;
	const descriptionImage3 = sameProject.descriptionImage3;
	const descriptionImage4 = sameProject.descriptionImage4;
    const descriptionImage5 = sameProject.descriptionImage5;
    const descriptionImage6 = sameProject.descriptionImage6;
    const adobeInDesignLogo = sameProject.button1;
    const microsoftPowerPointLogo = sameProject.button2;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`PLC MIDTERM MIXER | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-midtermmixer">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="midtermmixer-logo-title">
						<div className="midtermmixer-title">
							PCL CLIENT PRESENTATION
						</div>
						
				
					</div>
					<div className="midtermmixer-columns">
						{/* Left Column (35% Width) */}
						<div className="midtermmixer-left-column">
						Organized and executed a student-led Midterm Mixer event for PCL Construction, collaborating over two months to
deliver a well-rounded experience for attendees. The event included icebreakers, a panel discussion with full-time PCL
employees, and a hands-on building activity for students. As a Marketing Student, I created essential materials, including
a PowerPoint presentation, bingo cards, panelist name tags, building scorecards, and QR codes, ensuring a cohesive
visual style throughout. Additionally, I coordinated event flow, introduced the committee, and led multiple activities. I
designed a photo backdrop for student and panelist photos and ensured that all presentations and speeches aligned
smoothly with my visual materials.
						</div>
					</div>
					<div className="midtermmixer-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="midtermmixer-software">
                                <a href="https://www.adobe.com/ca/products/indesign.html">
									<img src={adobeInDesignLogo} alt="adobeInDesignLogo" />
								</a>
								<a href="https://www.microsoft.com/en-ca/microsoft-365/powerpoint">
									<img src={microsoftPowerPointLogo} alt="powerpoint" />
								</a>
							</div>
							<div className="midtermmixer-images">
                                {/* Top two images */}
                                <img src={descriptionImage1} alt="Image 1" className="grid-image" />
                                <img src={descriptionImage2} alt="Image 2" className="grid-image" />
                            </div>

                            <div className="midtermmixer-bottom-images">
                                {/* Bottom four images */}
                                <img src={descriptionImage3} alt="Image 3" className="grid-image" />
                                <img src={descriptionImage4} alt="Image 4" className="grid-image" />
                                <img src={descriptionImage5} alt="Image 5" className="grid-image" />
                                <img src={descriptionImage6} alt="Image 6" className="grid-image" />
                            </div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default MidtermMixer;
